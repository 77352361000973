/* global kakao */
import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import {connect} from "react-redux";
import {login, setPop} from "../actions/commonActions";
import {useParams} from "react-router-dom";
import DaumPostcode from 'react-daum-postcode';
import Parser from 'html-react-parser';
import $ from 'jquery';
import Axios from "axios";
import Description from "./Description";

// import Form from "../components/Form";
const { kakao } = window;

const DiagnosisShow = ({params, history, setPop}) => {
    // 증상
    let {id} = useParams();
    const [patient, setPatient] = useState({
        images: [],
        deliveryData: {},
        prescriptionData: {
            regdate: "",
            patientName: "",
            ssn: "",
            hospital_name: "",
            contact_number: "",
            disease: [{},{},{},{},{},{},{},{}],
            license_number: "",
            medicine: [],
        }
    });

    const [form, setForm] = useState({
        index: id,
        price: "", // 조제비
        memo: "", // 메모
        cost: 0, // 택배비
        invoice: "", // 송장번호
        delivery_company: "", // 택배회사
    });

    useEffect(() => {
        getPatient();

        window.PDFObject.embed("/file/example.pdf", "#pdf");
    }, []);

    const getPatient = () => {
        Axios.get(window.domain + "/api/pharmacist/karte/" + id)
            .then(response => {

                setPatient({
                    ...response.data.data,
                    deliveryData: {
                        ...response.data.deliveryData
                    },
                    prescriptionData: {
                        ...response.data.prescriptionData
                    }
                });

                console.log({
                    ...response.data.prescriptionData
                });
            });
    }

    const changeForm = (e) => {
        let value = e.target.value;
        let type = e.target.type;
        let name = e.target.name;

        if(type === "checkbox") {
            form[name] = form[name].includes(value) ? form[name].filter(data => data !== value) : [...form[name], value];

            return setForm({
                ...form,
                [name]: form[name]
            });
        }

        if(type === "file"){
            form[name] = e.target.files[0];

            return setForm({
                ...form,
                [name + "_thumb"] : URL.createObjectURL(e.target.files[0]),
                [name + "_name"] : e.target.files[0].name,
                [name] : form[name]
            });


        }

        form[name] = value;
    }

    const store = async (e) => {
        e.preventDefault();

        console.log(form);

        await axios.post(window.domain + "/api/pharmacist/preparation", {
            ...form,
        }).then(response => {
            if (response.data.status === 200) {
                setPop({
                    title: null,
                    description: `${patient.name} 님의 조제가 완료되었습니다.`,
                    needLogo: true,
                    buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => {history.push("/"); setPop(null)} }>확인</button>]
                });
            }
        }).catch(error => {
            setPop({
                title: null,
                description: error.response.data.message,
                needLogo: true,
                buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
            });
        });
    };

    return (
            <div className="reservations-create descriptions-create">
                <div className="container">
                    <div className="container-inner">
                        <div className="section-wrap section-wrap01">
                            <div className="section">
                                <div className="fragment">
                                    <div className="m-title type02">
                                        환자 처방 정보
                                    </div>

                                    <div className="mt-20"></div>

                                    <Description description={patient.prescriptionData} />
                                </div>
                            </div>
                        </div>

                        <div className="section-wrap">
                            <div className="section">
                                <div className="fragment">
                                    <div className="m-title type02">
                                        환자 기본 정보
                                    </div>

                                    <div className="mt-20"></div>

                                    <div className="m-boxes type02">
                                        <div className="m-box box-info">
                                            <h3 className="title">{patient.name}</h3>

                                            <div className="infos">
                                                <div className="info">
                                                    <p className="info-title">주민번호</p>
                                                    <p className="info-body">{patient.ssn}</p>
                                                </div>

                                                <div className="info">
                                                    <p className="info-title">전화번호</p>
                                                    <p className="info-body">{patient.phone}</p>
                                                </div>

                                                <div className="info">
                                                    <p className="info-title">접수일</p>
                                                    <p className="info-body">{patient.regdate}</p>
                                                </div>

                                                {/*<div className="mt-10"></div>
                                                <div className="info">
                                                    <p className="info-title">비고</p>
                                                    <p className="info-body">화상 진료 접수</p>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-28"></div>

                                <div className="fragment">
                                    <div className="m-title type02">
                                        증상
                                    </div>

                                    <div className="mt-20"></div>

                                    <div className="m-boxes type02">
                                        <div className="m-box box-answer">
                                    <pre>{patient.symptom}</pre>

                                            <div className="imgs">
                                                {patient.images.map((image, index) =>
                                                        <div className="m-ratioBox-wrap" key={index}>
                                                            <div className="m-ratioBox">
                                                                <img src={image.file_name} alt="" className={"pop-img"} onClick={e => setPop({
                                                                    img_url: image.file_name
                                                                })}/>
                                                            </div>
                                                        </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="mt-24"></div>

                                <div className="fragment">
                                    <div className="m-input-textarea type01">
                                        <textarea name="memo" id="" placeholder="메모" onChange={changeForm}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-wrap section-wrap03">
                            <div className="section">
                                <div className="fragment">
                                    <div className="m-title type02">
                                        약 수령 방법
                                    </div>

                                    <div className="mt-20"></div>

                                    <div className="m-boxes type02">
                                        <div className="m-box box-info">
                                            <h3 className="title">{patient.deliveryData.receive_type}</h3>

                                            <div className="infos">
                                                <div className="info width-100">
                                                    <p className="info-body">{patient.deliveryData.address1}</p>
                                                </div>

                                                <div className="info width-100">
                                                    <p className="info-body">{patient.deliveryData.address2}</p>
                                                </div>

                                                <div className="info width-100">
                                                    <p className="info-title">공동현관</p>
                                                    <p className="info-body">{patient.deliveryData.door_password}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-28"></div>

                                <div className="fragment fragment02">
                                    <div className="m-title type02 white">
                                        임시제목
                                    </div>

                                    <div className="mt-20"></div>

                                    <div className="comments">
                                        <p className="comment">
                                            모든 환자는 동일성분 다른 약 조제에 동의하였습니다.
                                        </p>
                                        <p className="comment">
                                            만약, 다른 약으로 변경하시는 경우에는 환자에게 정확한 설명을 부탁 드립니다.
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-24"></div>
                                

                                <div className="fragment">
                                    <div className="m-input-wrap">
                                        <div className="m-input-select type01">
                                            <select name="delivery_company" id="" onChange={changeForm}>
                                                <option value="" disabled={true} >택배사 선택</option>
                                                <option value="CJ택배">CJ택배</option>
                                                <option value="로젠택배">로젠택배</option>
                                                <option value="우체국택배">우체국택배</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-10"></div>
                                    <div className="m-input-wrap">
                                        <div className="m-input-text type01">
                                            <input type="text" name={"invoice"} placeholder={"송장번호"} onChange={changeForm}/>
                                        </div>
                                    </div>
                                    <div className="mt-10"></div>
                                    <div className="m-input-wrap">
                                        <div className="m-input-text type01">
                                            <input type="number" name={"cost"} placeholder={"택배비"} onChange={changeForm}/>
                                        </div>
                                    </div>
                                    <div className="mt-10"></div>

                                    <div className="box-price">
                                        <div className="left">
                                            <h3 className="title">조제비 입력</h3>

                                            <div className="box">
                                                <div className="m-input-text type01">
                                                    <input type="number" name={"price"} placeholder="0" onChange={changeForm}/>
                                                </div>
                                                원
                                            </div>
                                        </div>

                                        <button className="m-btn type02 bg-primary" onClick={store}>조제완료</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (user, token, onLogin) => {
            dispatch(login(user, token, onLogin));
        },

        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(null, mapDispatchToProps)(DiagnosisShow);