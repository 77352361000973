/* global kakao */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import {connect} from "react-redux";
import {login, setPop} from "../actions/commonActions";
import DaumPostcode from 'react-daum-postcode';
import Parser from 'html-react-parser';
import $ from 'jquery';

// import Form from "../components/Form";
const { kakao } = window;

const Register = ({login, history, setPop}) => {
    // 증상
    const [form, setForm] = useState({
        agrees: [], // 약관동의

        // profileFile: "", // 프로필 사진
        // introduce: "", // 자기소개

        id: "", // 아이디(이메일)
        pw1: "", // 비밀번호
        pw2: "", // 비밀번호 확인
        name: "", // 이름
        phone: "", // 전화번호
        code: "", // 전화번호 인증코드
        license_number: "", // 라이센스 번호
        license_file: "", // 라이센스 파일

        // department: [], // 진료과목 - 없음
        // symptom: [], // 증상 - 없음
        pharmacy_name: "", // 약국명 - 없음
        business_number: "", // 사업자번호
        business_file: "", // 사업자등록증 사본파일

        // ceo: "", // 대표자명 - 없음
        contact_number: "", // 대표번호

        address: "", // 주소
        address_detail: "", // 상세주소
        lat: "", // x
        lng: "", // y
    });

    useEffect(() => {
        getServicePolicy();

        $(".m-input-select.type01 .fake").click(function(){
            $(this).siblings(".options").toggle();
        });

        $(".m-input-select.type02 .value").click(function(){
            $(this).siblings(".options").toggle();
        });
        // initTestData();
    }, []);

    const getServicePolicy = () => {
        axios.get(window.domain + "/api/pharmacist/terms")
            .then(response => {
                if(response.data.terms)
                    return setServicePolicy(response.data.terms.replaceAll("\\n", "<br/>").replaceAll("\\r", ""));

                return setServicePolicy("테스트");
            }).catch(error => {
        });

        axios.get(window.domain + "/api/pharmacist/privacy")
                .then(response => {
                    if(response.data.privacy)
                        return setPrivacyPolicy(response.data.privacy.replaceAll("\\n", "<br/>").replaceAll("\\r", ""));

                    return setPrivacyPolicy("테스트");
                }).catch(error => {

        });
    }

    const bizNoFormatter = (num) => {
        var formatNum = num;

        if(num.length === 3)
            formatNum += "-";

        if(num.length === 6)
            formatNum += "-";

        return formatNum;
    }

    const changeForm = (e) => {
        let value = e.target.value;
        let type = e.target.type;
        let name = e.target.name;

        if(type === "checkbox") {
            form[name] = form[name].includes(value) ? form[name].filter(data => data !== value) : [...form[name], value];

            return setForm({
                ...form,
                [name]: form[name]
            });
        }

        if(type === "file"){
            form[name] = e.target.files[0];

            return setForm({
                ...form,
                [name + "_thumb"] : URL.createObjectURL(e.target.files[0]),
                [name + "_name"] : e.target.files[0].name,
                [name] : form[name]
            });
        }

        if(name === "business_number"){
            value = bizNoFormatter(value);
            e.target.value = bizNoFormatter(value);
        }

        form[name] = value;
    }

    // 약관 동의 관련
    const [servicePolicy, setServicePolicy] = useState("");
    const [activeServicePolicy, setActiveServicePolicy] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState("");
    const [activePrivacyPolicy, setActivePrivacyPolicy] = useState(false);


    const agreeAll = (e) => {
        e.preventDefault();

        form.agrees = form.agrees.length < 3 ? ["agree1", "agree2", "agree3"] : [];

        setForm({
            ...form,
            agrees: form.agrees
        });
    };

    const [showAuthNum, setShowAuthNum] = useState(false);

    const requestAuthNum = async () => {
        await axios.post(window.domain + "/api/pharmacist/authPhone", {phone: form.phone})
            .then(res => {
                // [문자 전송되지 않음] 인증번호가 넘어오면 alert로 확인시켜준다.
                if (res.data.status === 200) {
                    setPop({
                        title: null,
                        description: `인증번호가 전송되었습니다.`,
                        needLogo: true,
                        buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                    });

                    setShowAuthNum(true);
                }
            })
            .catch(error => {
                    setPop({
                        title: null,
                        description: "인증번호 전송에 실패했습니다.",
                        needLogo: true,
                        buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                    });
                }
            );
    };

    const verifyAuthNum = async () => {
        await axios.post(window.domain + "/api/pharmacist/confirmAuth", {phone: form.phone, code: form.code})
            .then(res => {
                if (res.data.status === 200) {
                    setPop({
                        title: null,
                        description: `인증되었습니다.`,
                        needLogo: true,
                        buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                    });
                }
            })
            .catch(error => {
                setPop({
                    title: null,
                    description: `인증에 실패했습니다.`,
                    needLogo: true,
                    buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                });
                }
            );
    };

    const verifyUserEmail = async () => {
        await axios.post(window.domain + "/api/pharmacist/idConfirm", {id: form.id})
            .then(res => {
                if (res.data.status === 200) {
                    setPop({
                        title: null,
                        description: `사용가능한 아이디입니다.`,
                        needLogo: true,
                        buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                    });
                }
            })
            .catch(error => {
                if(error.response.data && error.response.data.message){
                    setPop({
                        title: null,
                        description: error.response.data.message,
                        needLogo: true,
                        buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                    });
                }
            });
    };

    // 면허정보
    const verifyLicenseNum = async () => {
        await axios.post(window.domain + "/api/pharmacist/licenseConfirm", {license_number: form.license_number})
            .then(res => {
                if (res.data.status === 200) {
                    setPop({
                        title: null,
                        description: `입력 가능한 면허번호 입니다.`,
                        needLogo: true,
                        buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                    });
                }
            })
            .catch(error => {
                setPop({
                    title: null,
                    description: `확인되지 않는 면허입니다.`,
                    needLogo: true,
                    buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                });
                }
            );
    }

    // 주소 검색
    const [openPostcode, setOpenPostcode] = useState(false);

    const openSearchAddress = () => {
        setOpenPostcode(prev => !prev);
    };

    const selectAddress = (data) => {
        getCoords(data);

        setOpenPostcode(false);
    }

    const getCoords = (data) => {
        var geocoder = new kakao.maps.services.Geocoder();

        geocoder.addressSearch(data.address, function(result, status) {
            if (status === kakao.maps.services.Status.OK) {
                setForm({
                    ...form,
                    address: data.address,
                    lat: result[0].y,
                    lng: result[0].x
                });
            }
        });
    }

    const verifyBusinessNum = async () => {
        await axios.post(window.domain + "/api/pharmacist/businessConfirm", {business_number: form.business_number})
            .then(res => {
                if (res.data.status === 200) {
                    setPop({
                        title: null,
                        description: `등록 가능한 사업자번호입니다.`,
                        needLogo: true,
                        buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                    });
                }
            })
            .catch(error => {
                setPop({
                    title: null,
                    description: `등록 불가능한 사업자등록번호입니다.`,
                    needLogo: true,
                    buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
                });
                }
            );
    }

    const arrayToString = (items) => {
        let result = "";
        let length = items.length;

        items.map((item, index) => {
            if(index === length - 1)
                return result += item;

            return result += item + ",";
        });

        return result;
    }

    const register = async (e) => {
        e.preventDefault();

        if(!form.agrees.includes("agree1") || !form.agrees.includes("agree2"))
            return setPop({
                title: null,
                description: `필수약관에 동의해주세요.`,
                needLogo: true,
                buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => {setPop(null)} }>확인</button>]
            });

        const formData = new FormData();

        for(let key in form){
            formData.append(key, form[key]);
        }

        await axios.post(window.domain + "/api/pharmacist", formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if (res.data.status === 200) {
                setPop({
                    title: null,
                    description: `회원가입 신청이 완료 되었습니다.
                    보내주신 정보를 검토 후 운영진이 연락 드릴 것 입니다.
                    감사합니다.`,
                    needLogo: true,
                    buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => {history.push("/"); setPop(null)} }>확인</button>]
                });
            }
        }).catch(error => {
            setPop({
                title: null,
                description: error.response.data.message,
                needLogo: true,
                buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
            });
        });
    };

    return (
        <div className="area-register">
            <form onSubmit={register} encType="multipart/form-data">

                <div className="boxes">
                    <div className="box-wrap">
                        <div className="box box01">
                            <div className="box-logo">
                                <img src="/img/logo-1.png" alt="" className="logo"/>

                                <h3 className="title">회원가입</h3>
                            </div>

                            <div className="top">

                            </div>

                            <div className="bottom">
                                <div className="m-title type01">이용약관</div>

                                <div className={form.agrees.length === 3 ? 'm-input-checkbox type02 active' : 'm-input-checkbox type02'} onClick={agreeAll}>모두 동의합니다.</div>
                                <div className="mt-28"></div>

                                <div className="m-input-checkbox type01">
                                    <input type="checkbox" id="agree1" name="agrees" value={"agree1"} checked={form.agrees.includes("agree1")} onChange={changeForm}/>
                                    <label htmlFor="agree1">
                                        <div className="circle">
                                            <img src="/img/check-white.png" alt=""/>
                                        </div>

                                        <span className="text">(필수) 서비스 이용약관</span>

                                        <button type={"button"} className="btn-page" onClick={() => setActiveServicePolicy(true)}>
                                            <img src="/img/arrowRight-gray.png" alt=""/>
                                        </button>
                                    </label>
                                </div>

                                <div className="mt-24"></div>

                                <div className="m-input-checkbox type01">
                                    <input type="checkbox" id="agree2" name="agrees" value={"agree2"} checked={form.agrees.includes("agree2")} onChange={changeForm}/>

                                    <label htmlFor="agree2">
                                        <div className="circle">
                                            <img src="/img/check-white.png" alt=""/>
                                        </div>

                                        <span className="text">(필수) 개인정보 수집 및 이용 동의</span>

                                        <button type={"button"} className="btn-page" onClick={() => setActivePrivacyPolicy(true)}>
                                            <img src="/img/arrowRight-gray.png" alt=""/>
                                        </button>
                                    </label>
                                </div>

                                <div className="mt-24"></div>

                                <div className="m-input-checkbox type01">
                                    <input type="checkbox" id="agree3" name="agrees" value={"agree3"} checked={form.agrees.includes("agree3")} onChange={changeForm}/>

                                    <label htmlFor="agree3">
                                        <div className="circle">
                                            <img src="/img/check-white.png" alt=""/>
                                        </div>

                                        <span className="text">(선택) 알람 이용</span>

                                        <button type={"button"} className="btn-page">
                                            <img src="/img/arrowRight-gray.png" alt=""/>
                                        </button>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-wrap">
                        <div className="box box02">
                            <div className="m-title type01">개인정보</div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">이름</div>

                                <div className="m-input-text type01">
                                    <input type="text" placeholder="이름 입력" name={"name"} defaultValue={form.name} onChange={changeForm}/>
                                </div>


                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">휴대폰 번호</div>

                                <div className="m-input-withBtn type01">
                                    <div className="m-input m-input-text type01">
                                        <input type="text" placeholder="010-0000-0000" name={"phone"} defaultValue={form.phone} onChange={changeForm}/>
                                    </div>

                                    <button type="button" className="m-input-btn m-btn type02"
                                            onClick={requestAuthNum}>인증받기
                                    </button>
                                </div>


                            </div>

                            {showAuthNum && (
                                <div className="m-input-wrap">
                                    <div className="m-input-title type01">인증번호</div>

                                    <div className="m-input-withBtn type01">
                                        <div className="m-input m-input-text type01">
                                            <input type="text" placeholder="" name={"code"} defaultValue={form.code} onChange={changeForm}/>
                                        </div>

                                        <button type="button" className="m-input-btn m-btn type02"
                                                onClick={verifyAuthNum}>인증하기
                                        </button>
                                    </div>
                                </div>)}

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">귀하의 이메일</div>

                                <div className="m-input-withBtn type01">
                                    <div className="m-input m-input-text type01">
                                        <input type="text" placeholder="email@email.com" name={"id"} defaultValue={form.id} onChange={changeForm}/>
                                    </div>

                                    <button type="button" className="m-input-btn m-btn type02" onClick={verifyUserEmail}>중복확인
                                    </button>
                                </div>

                                <p className="m-input-comment">* 로그인 아이디로 사용됩니다.</p>


                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">비밀번호</div>

                                <div className="m-input m-input-text type01">
                                    <input type="password" placeholder="비밀번호" name={"pw1"} defaultValue={form.pw1} onChange={changeForm}/>
                                </div>
                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01"> 비밀번호 확인</div>

                                <div className="m-input m-input-text type01">
                                    <input type="password" placeholder="비밀번호 확인" name={"pw2"} defaultValue={form.pw2} onChange={changeForm}/>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="box-wrap">
                        <div className="box box03">
                            <div className="m-title type01">진료과목분야</div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">면허 번호</div>

                                <div className="m-input-withBtn type01">
                                    <div className="m-input m-input-text type01">
                                        <input type="text" placeholder="면허 번호를 입력하여 주세요." name={"license_number"} defaultValue={form.license_number} onChange={changeForm}/>
                                    </div>

                                    <button type="button" className="m-input-btn m-btn type02" onClick={verifyLicenseNum}>중복확인</button>
                                </div>
                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">면허증 사본</div>

                                <div className="m-input-withBtn type01">
                                    <div className="m-input m-input-text type01">
                                        <input type="text" disabled={true} placeholder="사본을 첨부하여 주세요." value={form.license_file_name}/>
                                    </div>

                                    <input type="file" name={"license_file"} id={"license_file"} accept="image/*" onChange={changeForm} style={{display: 'none'}}/>
                                    <label htmlFor={"license_file"} className="m-input-btn m-btn type02">파일 찾기</label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="box-wrap">
                        <div className="box box02">
                            <div className="m-title type01">약국정보</div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">약국명</div>

                                <div className="m-input m-input-text type01">
                                    <input type="text" placeholder="약국명" name={"pharmacy_name"} defaultValue={form.pharmacy_name} onChange={changeForm}/>
                                </div>
                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">주소</div>

                                <div className="m-input-text type01" onClick={openSearchAddress}>
                                    <input type="text" placeholder="지번, 도로명, 건물명 입력"
                                           disabled={true}
                                           value={form.address}
                                    />
                                    <img src="/img/search3_24.png" alt="" className="m-input-text-deco"/>
                                </div>
                                {openPostcode && (
                                    <div style={{
                                        position: "fixed",
                                        width: 800,
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 999,
                                        padding: 20,
                                        backgroundColor: 'white',
                                        boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)'
                                    }}>
                                        <DaumPostcode
                                            onComplete={selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                                            autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                                            defaultQuery='' // 팝업을 열때 기본적으로 입력되는 검색어
                                        />
                                    </div>
                                )}

                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">상세 주소</div>

                                <div className="m-input m-input-text type01">
                                    <input type="text" placeholder="상세 주소 입력" name={"address_detail"} defaultValue={form.address_detail} onChange={changeForm}/>
                                </div>
                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">대표번호</div>

                                <div className="m-input m-input-text type01">
                                    <input type="text" placeholder="지역번호까지 함께 입력해 주세요." name={"contact_number"} defaultValue={form.contact_number} onChange={changeForm}/>
                                </div>


                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">사업자등록번호</div>

                                <div className="m-input-withBtn type01">
                                    <div className="m-input m-input-text type01">
                                        <input type="text" placeholder="사업자등록번호" name={"business_number"} defaultValue={form.business_number} onChange={changeForm}/>
                                    </div>

                                    <button type="button" className="m-input-btn m-btn type02" onClick={verifyBusinessNum}>중복확인
                                    </button>
                                </div>
                            </div>

                            <div className="m-input-wrap">
                                <div className="m-input-title type01">사업자등록번호 사본</div>

                                <div className="m-input-withBtn type01">
                                    <div className="m-input m-input-text type01">
                                        <input type="text" disabled={true} placeholder="사본을 첨부하여 주세요." value={form.business_file_name} />
                                    </div>

                                    <input type="file" id={"business_file"} accept="image/*" defaultValue={form.business_file} name={"business_file"} onChange={changeForm} style={{display: 'none'}}/>
                                    <label htmlFor={"business_file"} className="m-input-btn m-btn type02">파일 찾기</label>
                                </div>


                            </div>

                            <div className="mt-80"></div>

                            <button className="m-btn type01 width-100">가입하기</button>
                        </div>
                    </div>
                </div>

            </form>

            {
                activeServicePolicy ?
                    <div className="m-pop type01">
                        <div className="m-pop-inner">
                            <h3 className="m-pop-title">서비스 이용약관</h3>

                            <div className="section">{Parser(servicePolicy)}</div>

                            <div className="mt-40"></div>

                            <div className="btns">
                                <button type="button" className="m-btn type01" onClick={() => setActiveServicePolicy(false)}>확인</button>
                            </div>
                        </div>
                    </div> : ""
            }

            {
                activePrivacyPolicy ?
                        <div className="m-pop type01">
                            <div className="m-pop-inner">
                                <h3 className="m-pop-title">개인정보 처리방침</h3>

                                <div className="section">{Parser(privacyPolicy)}</div>

                                <div className="mt-40"></div>

                                <div className="btns">
                                    <button type="button" className="m-btn type01" onClick={() => setActivePrivacyPolicy(false)}>확인</button>
                                </div>
                            </div>
                        </div> : ""
            }

        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (user, token, onLogin) => {
            dispatch(login(user, token, onLogin));
        },

        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(null, mapDispatchToProps)(Register);