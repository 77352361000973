import React, {Fragment, useEffect, useState} from 'react';
import {setDescription} from "../actions/commonActions";
import {connect} from 'react-redux';
import Axios from "axios";
import {useParams} from "react-router-dom";

const Description = ({}) => {
    // 증상
    let {id} = useParams();
    let [description, setDescription] = useState({
        regdate: "",
        patientName: "",
        ssn: "",
        hospital_name: "",
        contact_number: "",
        disease: [{},{},{},{},{},{},{},{}],
        license_number: "",
        medicine: [],
    });

    const [patient, setPatient] = useState({
        images: [],
        deliveryData: {},
        prescriptionData: {
            regdate: "",
            patientName: "",
            ssn: "",
            hospital_name: "",
            contact_number: "",
            disease: [{},{},{},{},{},{},{},{}],
            license_number: "",
            medicine: [],
        }
    });

    useEffect(() => {
        getPatient();

        window.PDFObject.embed("/file/example.pdf", "#pdf");
    }, []);

    const getPatient = () => {
        Axios.get(window.domain + "/api/pharmacist/karte/" + id)
            .then(response => {

                setPatient({
                    ...response.data.data,
                    deliveryData: {
                        ...response.data.deliveryData
                    },
                    prescriptionData: {
                        ...response.data.prescriptionData
                    }
                });

                setDescription({
                    ...response.data.prescriptionData
                })

            });
    }

    return <div className={"description"}>
        <div className="description-show paper" id={"capture"}>
            <div className="content">

                <table>
                    {/* <colgroup>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                    <col style="width:10%;"/>
                </colgroup>
                */}
                    <tbody>
                    <tr>
                        <td colSpan="2">
                            {/*■ 활성화 네모 모양*/}
                            □ 의료보험
                        </td>
                        <td colSpan="2">
                            □ 의료보호
                        </td>
                        <td colSpan="2">
                            □ 산재보험
                        </td>
                        <td colSpan="2">
                            □ 자동차보험
                        </td>
                        <td colSpan="2">
                            □ 기타 ( )
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2">요양 기관번호</th>
                        <td colSpan="8"></td>
                    </tr>
                    <tr>
                        <th colSpan="2">교부날짜</th>
                        <td colSpan="3">
                            {description.regdate}
                        </td>
                        <th colSpan="2">교부번호</th>
                        <td colSpan="3"></td>
                    </tr>
                    <tr>
                        <th>환자</th>
                        <th colSpan="2">성명</th>
                        <td colSpan="2">
                            {description.patientName}
                        </td>
                        <th colSpan="2">주민등록번호</th>
                        <td colSpan="3">
                            {description.ssn}
                        </td>
                    </tr>
                    <tr>
                        <th rowSpan="3">의료기관</th>
                        <th colSpan="2">명칭</th>
                        <td colSpan="5">
                            {description.hospital_name}
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2">전화번호</th>
                        <td colSpan="2">
                            {description.contact_number}
                        </td>
                        <th colSpan="2">FAX번호</th>
                        <td colSpan="3"></td>
                    </tr>
                    <tr>
                        <th colSpan="2">e-mail</th>
                        <td colSpan="5"></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">질병분류기호</th>
                        <td>
                            {description.disease[0].code}
                        </td>
                        <td>
                            {description.disease[1].code}
                        </td>
                        <td>
                            {description.disease[2].code}
                        </td>
                        <td>
                            {description.disease[3].code}
                        </td>
                        <th rowSpan="2" colSpan="2">
                            처방의료인의
                            <br/>성명
                        </th>
                        <td rowSpan="2" colSpan="3">
                            {description.name} (인)
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {description.disease[4].code}
                        </td>
                        <td>
                            {description.disease[5].code}
                        </td>
                        <td>
                            {description.disease[6].code}
                        </td>
                        <td>
                            {description.disease[7].code}
                        </td>
                    </tr>
                    <tr>
                        <th>면허종별</th>
                        <td colSpan="4"></td>
                        <th colSpan="2">면허번호</th>
                        <td colSpan="3">제 {description.license_number}호</td>
                    </tr>
                    <tr>
                        <td colSpan="10">
                            * 환자의 요구가 있을 때에는 질병분류기호를 기재하지 아니합니다.
                        </td>
                    </tr>
                    <tr>
                        <th rowSpan="2" colSpan="3">처방의약품의 명칭</th>
                        <th colSpan="2">1회</th>
                        <th rowSpan="2">총 투약일수</th>
                        <th rowSpan="2" colSpan="4">복용법</th>
                    </tr>
                    <tr>
                        <th>투약량</th>
                        <th>투여횟수</th>
                    </tr>
                    {description.medicine.map(item =>
                        <tr>
                            <td colSpan="3">{item.prescription}</td>
                            <td>{item.onceDosage}</td>
                            <td>{item.dayDosage}</td>
                            <td>{item.daysDosage}</td>
                            <td>{item.usage}</td>
                        </tr>
                    )
                    }

                    <tr>
                        <th colSpan="6">
                            주사제 처방내역(■ 원내조제, □ 원외처방)
                        </th>
                        <th colSpan="4">조제시 참고사항</th>
                    </tr>
                    <tr>
                        <td colSpan="6">

                        </td>
                        <td colSpan="4">

                        </td>
                    </tr>
                    <tr>
                        <td colSpan="6">

                        </td>
                        <td colSpan="4">

                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">사용기간</td>
                        <td colSpan="3">교부일 부터( )일간</td>
                        <td colSpan="5">사용기간 내에 약국에 제출하여야 합니다.</td>
                    </tr>
                    <tr>
                        <th colSpan="10">의약품 조제내역</th>
                    </tr>
                    <tr>
                        <th rowSpan="4">조제내역</th>
                        <th colSpan="2">조제기관의 명칭</th>
                        <td colSpan="4"></td>
                        <td colSpan="3" rowSpan="4">처방의 변경, 수정, 확인, <br/>대체시 그 내용 등</td>
                    </tr>
                    <tr>
                        <th colSpan="2">조제기관의 명칭</th>
                        <td colSpan="4"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


};

const mapState = (state) => {
    return {

    }
};

const mapDispatch = (dispatch) => {
    return {

    }
};

export default connect(mapState, mapDispatch)(Description);
