/* global kakao */
import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import {connect} from "react-redux";
import {login, setPop} from "../actions/commonActions";
import {useParams} from "react-router-dom";
import DaumPostcode from 'react-daum-postcode';
import Parser from 'html-react-parser';
import $ from 'jquery';
import Axios from "axios";
import {Link} from "react-router-dom";

// import Form from "../components/Form";
const { kakao } = window;

const DiagnosisIndex = ({ setPop}) => {
    let {date} = useParams();
    let [activeDate, setActiveDate] = useState(date);
    let [items, setItems] = useState([]);

    const getFormatDate = (date) => {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : date.getMonth() + 1;
        let day = (date.getDate()) < 10 ? ("0" + date.getDate()) : date.getDate();

        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        getDiagnosis();
    }, []);

    const getDiagnosis = (date) => {
        Axios.get(window.domain + "/api/pharmacist/KarteList", {
            params: {
                date: date ? date : activeDate
            }
        }).then(response => {
            setItems(response.data.list);
        });
    }

    const prev = () => {
        let newDate = new Date(activeDate);

        newDate.setDate(newDate.getDate() - 1);

        setActiveDate(getFormatDate(newDate));

        getDiagnosis(getFormatDate(newDate));
    }

    const next = () => {
        let newDate = new Date(activeDate);

        newDate.setDate(newDate.getDate() + 1);

        setActiveDate(getFormatDate(newDate));

        getDiagnosis(getFormatDate(newDate));
    }

    return (
        <div className="reservations-index">
            <div className="container">
                <div className="container-inner">
                    <div className="container-top">
                        <button type="button" className="btn-back" onClick={() => window.history.back()}>
                            <img src="/img/arrowBack.png" alt=""/>
                            돌아가기
                        </button>
                    </div>

                    <div className="m-title type02">
                        총 조제 건수

                        <div className="m-input-date type02">
                            <button className="btn-arrow">
                                <img src="/img/arrowLeft-black.png" alt="" onClick={prev}/>
                            </button>

                            <span className="text">{activeDate}</span>

                            <button className="btn-arrow btn-next">
                                <img src="/img/arrowLeft-black.png" alt="" onClick={next}/>
                            </button>
                        </div>
                    </div>

                    <div className="mt-20"></div>

                    <div className="m-table type01">
                        <div className="thead">
                            <div className="tr">
                                <div className="th">이름</div>
                                <div className="th">주민등록번호</div>
                                <div className="th">전화번호</div>
                                <div className="th">접수일</div>
                                <div className="th">상태</div>
                                <div className="th">처방전</div>
                            </div>
                        </div>
                        <div className="tbody">
                            {
                                items.length === 0 ?
                                    <div className="tr empty">
                                        대기 환자가 없습니다.
                                    </div> : ""
                            }

                            {
                                items.map(item =>
                                    <div className="tr" key={item.index}>
                                        <div className="td">{item.name}</div>
                                        <div className="td">{item.ssn}</div>
                                        <div className="td">{item.phone}</div>
                                        <div className="td">2022.09.26</div>
                                        <div className="td">
                                            {
                                                item.status == 1 || item.status == 2
                                                ? <div className="m-state type01 state01">처방전 미발행</div>
                                                : <div className="m-state type01 state02">처방전 발행완료</div>
                                            }

                                        </div>
                                        <div className="td">
                                            {
                                                item.status == 1 || item.status == 2
                                                    ? <Link to={"/diagnosis/" + item.index}>처방전 입력</Link>
                                                    : <Link to={"/descriptions/" + item.index}>처방전 보기</Link>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (user, token, onLogin) => {
            dispatch(login(user, token, onLogin));
        },

        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(null, mapDispatchToProps)(DiagnosisIndex);