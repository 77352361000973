import React from 'react';
import {Provider} from 'react-redux';
import store from './store';
import Login from './pages/Login';
import Register from './pages/Register';
import Main from './pages/Main';
import DiagnosisShow from './pages/DiagnosisShow';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import UserInfo from './보관/UserInfo';
import Header from "./components/Header";
import NoticesIndex from "./pages/NoticesIndex";
import DiagnosisIndex from "./pages/DiagnosisIndex";
import Edit from "./pages/Edit";
import Pop from "./components/Pop";
import AuthRoute from "./components/AuthRoute";
import Footer from "./components/Footer";
import Description from "./pages/Description";
import DescriptionShow from "./pages/DescriptionShow";
window.store = store;

const App = () => {

	return (
		<Provider store={store}>

			<BrowserRouter>
				<Pop />

				<Header />

				<main className="main">
					<Switch>
						<AuthRoute exact path={"/#"} component={Main} />
						<AuthRoute exact path={"/"} component={Main} />
						<AuthRoute exact path={"/userInfo"} component={UserInfo} />
						<AuthRoute exact path={"/diagnosis/list/:date"} component={DiagnosisIndex} />
						<AuthRoute exact path={"/diagnosis/:id"} component={DiagnosisShow} />
						<AuthRoute exact path={"/notices"} component={NoticesIndex} />
						<AuthRoute exact path={"/edit"} component={Edit} />
						<AuthRoute exact path={"/descriptions/:id"} component={DescriptionShow} />
						<Route exact path={"/login"} component={Login} />
						<Route exact path={"/register"} component={Register} />


					</Switch>
				</main>


				<Footer />
			</BrowserRouter>

		</Provider>
	);
};

export default App;
