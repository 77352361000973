import React, {Fragment, useEffect, useState} from 'react';
import {setPop} from "../actions/commonActions";
import {connect} from 'react-redux';
import Parser from "html-react-parser";

const SchedulePop = ({defaultValue, setActiveSchedulePop, update}) => {
    let [times, setTimes] = useState(defaultValue);

    useEffect(() => {
    }, []);

    const changeHoliday = (index) => {
        times[index].active = !times[index].active;

        setTimes([...times]);
    }

    const changeTime = (e, index) => {
        times[index][e.target.name] = e.target.value;

        setTimes([...times]);
    }

    const isHoliday = (index) => {
        return times[index].active === false ? true : false;
    };

    return (
        <div className="m-pop m-pop-schedule type02">
            <div className="m-pop-inner">
                <div className="content">
                    <div className="fragment">
                        <h3 className="title">정기 휴무 선택</h3>
                        <div className="m-input-checkboxes">
                            <div className="m-input-checkbox type01">
                                <input type="checkbox" id={"0"} onChange={() => changeHoliday(0)} checked={isHoliday(0)} />
                                <label htmlFor="0">
                                    <div className="circle">
                                        <img src="/img/check-white.png" alt=""/>
                                    </div>

                                    <span className="text">월</span>
                                </label>
                            </div>

                            <div className="m-input-checkbox type01">
                                <input type="checkbox" id={"1"} onChange={() => changeHoliday(1)}  checked={isHoliday(1)} />
                                <label htmlFor="1">
                                    <div className="circle">
                                        <img src="/img/check-white.png" alt=""/>
                                    </div>

                                    <span className="text">화</span>
                                </label>
                            </div>

                            <div className="m-input-checkbox type01">
                                <input type="checkbox" id={"2"} onChange={() => changeHoliday(2)}   checked={isHoliday(2)}/>
                                <label htmlFor="2">
                                    <div className="circle">
                                        <img src="/img/check-white.png" alt=""/>
                                    </div>

                                    <span className="text">수</span>
                                </label>
                            </div>

                            <div className="m-input-checkbox type01">
                                <input type="checkbox" id={"3"} onChange={() => changeHoliday(3)}   checked={isHoliday(3)}/>
                                <label htmlFor="3">
                                    <div className="circle">
                                        <img src="/img/check-white.png" alt=""/>
                                    </div>

                                    <span className="text">목</span>
                                </label>
                            </div>

                            <div className="m-input-checkbox type01">
                                <input type="checkbox" id={"4"} onChange={() => changeHoliday(4)}   checked={isHoliday(4)}/>
                                <label htmlFor="4">
                                    <div className="circle">
                                        <img src="/img/check-white.png" alt=""/>
                                    </div>

                                    <span className="text">금</span>
                                </label>
                            </div>

                            <div className="m-input-checkbox type01">
                                <input type="checkbox" id={"5"} onChange={() => changeHoliday(5)} checked={isHoliday(5)}  />
                                <label htmlFor="5">
                                    <div className="circle">
                                        <img src="/img/check-white.png" alt=""/>
                                    </div>

                                    <span className="text">토</span>
                                </label>
                            </div>

                            <div className="m-input-checkbox type01">
                                <input type="checkbox" id={"6"} onChange={() => changeHoliday(6)}  checked={isHoliday(6)}/>
                                <label htmlFor="6">
                                    <div className="circle">
                                        <img src="/img/check-white.png" alt=""/>
                                    </div>

                                    <span className="text">일</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="mt-24"></div>

                    <div className="fragment">
                        <h3 className="title">영업 시간 입력</h3>

                        <div className="m-input-wrap">
                            <h3 className="title">월</h3>
                            <div className="inputs">

                                {
                                    times[0].active
                                        ? <Fragment>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"open"} defaultValue={times[0].open} onChange={(e) => {changeTime(e, 0)}}  />
                                            </div>
                                            <span className="deco">~</span>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"close"} defaultValue={times[0].close} onChange={(e) => {changeTime(e, 0)}}  />
                                            </div>
                                        </Fragment>
                                        : "정기 휴무"
                                }

                                {/*<div className="m-input-checkbox type01">
                                    <input type="checkbox" id="all" />
                                    <label htmlFor="all">
                                        <div className="circle">
                                            <img src="/img/check-white.png" alt=""/>
                                        </div>

                                        <span className="text">모두 적용</span>
                                    </label>
                                </div>*/}
                            </div>
                        </div>

                        <div className="m-input-wrap">
                            <h3 className="title">화</h3>
                            <div className="inputs">
                                {
                                    times[1].active
                                        ? <Fragment>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"open"} defaultValue={times[1].open} onChange={(e) => {changeTime(e, 1)}}  />
                                            </div>
                                            <span className="deco">~</span>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"close"} defaultValue={times[1].close} onChange={(e) => {changeTime(e, 1)}}  />
                                            </div>
                                        </Fragment>
                                        : "정기 휴무"
                                }
                            </div>
                        </div>

                        <div className="m-input-wrap">
                            <h3 className="title">수</h3>
                            <div className="inputs">
                                {
                                    times[2].active
                                        ? <Fragment>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"open"} defaultValue={times[2].open} onChange={(e) => {changeTime(e, 2)}}  />
                                            </div>
                                            <span className="deco">~</span>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"close"} defaultValue={times[2].close} onChange={(e) => {changeTime(e, 2)}}  />
                                            </div>
                                        </Fragment>
                                        : "정기 휴무"
                                }
                            </div>
                        </div>

                        <div className="m-input-wrap">
                            <h3 className="title">목</h3>
                            <div className="inputs">
                                {
                                    times[3].active
                                        ? <Fragment>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"open"} defaultValue={times[3].open} onChange={(e) => {changeTime(e, 3)}}  />
                                            </div>
                                            <span className="deco">~</span>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"close"} defaultValue={times[3].close} onChange={(e) => {changeTime(e, 3)}}  />
                                            </div>
                                        </Fragment>
                                        : "정기 휴무"
                                }
                            </div>
                        </div>

                        <div className="m-input-wrap">
                            <h3 className="title">금</h3>
                            <div className="inputs">
                                {
                                    times[4].active
                                        ? <Fragment>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"open"} defaultValue={times[4].open} onChange={(e) => {changeTime(e, 4)}}  />
                                            </div>
                                            <span className="deco">~</span>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"close"} defaultValue={times[4].close} onChange={(e) => {changeTime(e, 4)}}  />
                                            </div>
                                        </Fragment>
                                        : "정기 휴무"
                                }
                            </div>
                        </div>

                        <div className="m-input-wrap">
                            <h3 className="title">토</h3>
                            <div className="inputs">
                                {
                                    times[5].active
                                        ? <Fragment>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"open"} defaultValue={times[5].open} onChange={(e) => {changeTime(e, 5)}}  />
                                            </div>
                                            <span className="deco">~</span>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"close"} defaultValue={times[5].close} onChange={(e) => {changeTime(e, 5)}}  />
                                            </div>
                                        </Fragment>
                                        : "정기 휴무"
                                }
                            </div>
                        </div>

                        <div className="m-input-wrap">
                            <h3 className="title">일</h3>
                            <div className="inputs">
                                {
                                    times[6].active
                                        ? <Fragment>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"open"} defaultValue={times[6].open} onChange={(e) => {changeTime(e, 6)}}  />
                                            </div>
                                            <span className="deco">~</span>
                                            <div className="m-input-text type01">
                                                <input type="text" name={"close"} defaultValue={times[6].close} onChange={(e) => {changeTime(e, 6)}}  />
                                            </div>
                                        </Fragment>
                                        : "정기 휴무"
                                }
                            </div>
                        </div>
                    </div>

                    <div className="mt-20"></div>

                    <div className="btns">
                        <button className="m-btn type02 bg-lightGray" onClick={() => setActiveSchedulePop(false)}>닫기</button>
                        <button className="m-btn type02" onClick={() => update({ph_info:[...times]}, () => {setActiveSchedulePop(false); window.location.reload()})}>저장하기</button>
                    </div>
                </div>
            </div>
        </div>
    );
    return null;
};

const mapState = (state) => {
    return {
        pop: state.common.pop
    }
};

const mapDispatch = (dispatch) => {
    return {
        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(mapState, mapDispatch)(SchedulePop);
